import { useMemo } from 'react';
import { MenuItem, ListItemIcon, Stack, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import Link from 'next/link';
import { SETTINGS_SIDE_BAR_WIDTH } from '../../../utils/dimensionsAndBreakpoints';
import Icon from '../../shared/Icon';
import { FeatureEnum, useFeatureFlags } from '../../../hooks';

type Setting = {
  label: string;
  pathPattern?: string; // static path is used to check selected state for settings that has dynamic path
  path: string;
  icon: JSX.Element;
  group?: string;
  hidden?: boolean;
};

const tuneSettings: Setting[] = [
  {
    label: 'Persona',
    path: '/tune/persona',
    icon: <Icon name='heart-spark' />,
  },
  {
    label: 'Policies',
    path: '/tune/policies',
    icon: <Icon name='chat-settings' />,
  },
];

export interface SettingsSidebarProps {
  currentPathPattern: string; // Contains route pattern (e.g., '/workspace/[workspaceId]') rather than resolved path
  workspaceId: string;
}

const SettingsSidebar = ({
  currentPathPattern,
  workspaceId,
}: SettingsSidebarProps) => {
  const { isFeatureEnabled } = useFeatureFlags();

  const organizationSettings = useMemo(
    () => [
      {
        label: 'Chat logs',
        path: '/organization-settings/chat-logs',
        icon: <Icon name='chat-logs' />,
        hidden: !isFeatureEnabled(FeatureEnum.EnvChatLogs),
      },
      {
        label: 'Branding',
        path: '/organization-settings/branding',
        icon: <Icon name='palette' />,
      },
      {
        label: 'Organization users',
        path: '/organization-settings/users',
        icon: <Icon name='user-multiple' />,
      },
      {
        label: 'Data controls',
        path: '/organization-settings/data-control',
        icon: <Icon name='data-connect' />,
      },
      {
        label: 'Embed integration',
        path: '/organization-settings/embed-integration',
        icon: <Icon name='browser-code' />,
      },
    ],
    [isFeatureEnabled],
  );

  const workspaceSettings: Setting[] = useMemo(
    () => [
      {
        label: 'Workspace settings',
        pathPattern: '/workspace-settings/[workspaceId]/general',
        path: `/workspace-settings/${workspaceId}/general`,
        icon: <Icon name='settings' />,
      },
      {
        label: 'Workspace users',
        pathPattern: '/workspace-settings/[workspaceId]/users',
        path: `/workspace-settings/${workspaceId}/users`,
        icon: <Icon name='user-multiple' />,
      },
      {
        label: 'Manage data',
        pathPattern: '/workspace-settings/[workspaceId]/manage-data',
        path: `/workspace-settings/${workspaceId}/manage-data`,
        icon: <Icon name='data' />,
      },
      {
        label: 'Integrations',
        pathPattern: '/workspace-settings/[workspaceId]/integrations',
        path: `/workspace-settings/${workspaceId}/integrations`,
        icon: <Icon name='puzzle' />,
      },
      {
        label: 'Chat logs',
        pathPattern: '/workspace-settings/[workspaceId]/chat-logs',
        path: `/workspace-settings/${workspaceId}/chat-logs`,
        icon: <Icon name='chat-logs' />,
      },
    ],
    [workspaceId],
  );
  const createSettings = useMemo<Setting[]>(
    () => [
      {
        label: 'Saved',
        path: '/create/queries',
        icon: <Icon name='bookmark-multi' />,
        group: 'Curate',
        hidden: !isFeatureEnabled(FeatureEnum.Queries),
      },
      {
        label: 'Topics',
        path: '/create/topics',
        icon: <Icon name='chat' />,
        group: 'Combine',
      },
      {
        label: 'Stacks',
        path: '/create/stacks',
        icon: <Icon name='stack' />,
        group: 'Combine',
        hidden: !isFeatureEnabled(FeatureEnum.Stacks),
      },
    ],
    [isFeatureEnabled],
  );

  const sidebarMap = useMemo<Record<string, Setting[]>>(
    () => ({
      'workspace-settings': workspaceSettings,
      'organization-settings': organizationSettings,
      tune: tuneSettings,
      create: createSettings,
    }),
    [createSettings, organizationSettings, workspaceSettings],
  );

  const segment1 = currentPathPattern.split('/')[1] ?? '';
  const settings = useMemo(
    () => (sidebarMap[segment1] ?? []).filter((item) => !item.hidden),
    [segment1, sidebarMap],
  );
  const groupedSettings = useMemo(() => {
    const groups = settings.reduce((acc, setting) => {
      if (setting.group) {
        if (!acc[setting.group]) {
          acc[setting.group] = [];
        }
        acc[setting.group].push(setting);
      } else {
        // If no group is specified, add to the default group with an empty string key
        if (!acc['']) {
          acc[''] = [];
        }
        acc[''].push(setting);
      }
      return acc;
    }, {} as Record<string, Setting[]>);

    return Object.keys(groups).map((group) => ({
      label: group,
      settings: groups[group],
    }));
  }, [settings]);

  const showGroup = groupedSettings.length > 1;

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    color: theme.palette.text.secondary,
    '&.MuiButtonBase-root:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      '& .MuiListItemIcon-root': {
        color: theme.palette.icon.active,
      },
    },
  }));

  if (settings.length === 0) {
    return <></>;
  }

  return (
    <Stack
      padding={2}
      width={SETTINGS_SIDE_BAR_WIDTH}
      borderRight={(theme) => `1px solid ${theme.palette.border.light}`}
      justifyContent='space-between'
    >
      <Stack>
        {showGroup &&
          groupedSettings.map((group, index) => (
            <Stack
              key={group.label}
              spacing={1}
              borderTop={
                index !== 0
                  ? (theme) => `1px solid ${theme.palette.border.light}`
                  : 'none'
              }
              paddingTop={index !== 0 ? 2 : 0}
              pb={2}
            >
              <Box p={1.5}>
                <Typography variant='h6' color='text.primary'>
                  {group.label}
                </Typography>
              </Box>
              {group.settings.map((setting) => (
                <Link href={setting.path} key={setting.label}>
                  <StyledMenuItem
                    selected={isSelected(currentPathPattern, setting)}
                  >
                    <ListItemIcon>{setting.icon}</ListItemIcon>
                    <Typography variant='h6'>{setting.label}</Typography>
                  </StyledMenuItem>
                </Link>
              ))}
            </Stack>
          ))}
        {!showGroup &&
          settings.map((setting) => (
            <Link href={setting.path} key={setting.label}>
              <StyledMenuItem
                selected={isSelected(currentPathPattern, setting)}
              >
                <ListItemIcon>{setting.icon}</ListItemIcon>
                <Typography variant='h6'>{setting.label}</Typography>
              </StyledMenuItem>
            </Link>
          ))}
      </Stack>
    </Stack>
  );
};

export default SettingsSidebar;

const isSelected = (currentPathPattern: string, setting: Setting) => {
  if (setting.pathPattern) {
    return currentPathPattern === setting.pathPattern;
  }
  return currentPathPattern === setting.path;
};

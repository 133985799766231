import { useCallback } from 'react';
import { NextRouter, useRouter } from 'next/router';
import {
  generateLinkForEmbeddedContent,
  Routes,
} from '@madeinventive/core-types';

import {
  AuthorizedEmbedding,
  useEmbeddingContext,
} from '../contexts/EmbeddingProvider';
import { useToast } from './useToast';

interface CopyResponseLinkOptions {
  onCopied?: () => void;
  disableToast?: boolean;
}

export const useCopyLink = () => {
  const router = useRouter();
  const { authorizedEmbedding } = useEmbeddingContext();
  const { showSuccessToast, showErrorToast } = useToast();

  const copyChatLink = useCallback(
    async (
      chatQueryParams?: {
        workspaceId?: string;
        chatThreadId?: string;
        chatResponseId?: string;
      },
      options?: CopyResponseLinkOptions,
    ) => {
      // copy link
      const generatedLink = generateCopyLink(
        router,
        authorizedEmbedding,
        chatQueryParams,
      );
      if (!generatedLink) {
        if (!options?.disableToast) showErrorToast('Failed to generate link.');
        return;
      }
      try {
        await navigator.clipboard.writeText(generatedLink);
        if (!options?.disableToast) showSuccessToast('Link copied.');
        if (options?.onCopied) options.onCopied();
      } catch (err) {
        console.error('Failed to copy URL: ', err);
        if (!options?.disableToast)
          showErrorToast('Failed to copy to clipboard.');
      }
    },
    [showSuccessToast, router, authorizedEmbedding, showErrorToast],
  );

  return {
    copyChatLink,
  };
};

function generateCopyLink(
  router: NextRouter,
  authorizedEmbedding?: AuthorizedEmbedding,
  chatQueryParams?: {
    workspaceId?: string;
    chatThreadId?: string;
    chatResponseId?: string;
  },
): string | undefined {
  const { workspaceId, chatThreadId, chatResponseId } = chatQueryParams || {};
  const hashValue = chatResponseId;
  const hostUrl = authorizedEmbedding?.getHostUrl();
  if (authorizedEmbedding && hostUrl) {
    // If embedded, generate a link using the hostUrl
    const rawQuery = { ...router.query };
    const queryObj: Record<string, string> = Object.keys(rawQuery).reduce(
      (acc, key) => {
        const value = rawQuery[key];
        acc[key] = Array.isArray(value) ? value.join(',') : value || '';
        return acc;
      },
      {} as Record<string, string>,
    );

    return generateLinkForEmbeddedContent(hostUrl, queryObj, hashValue);
  } else if (workspaceId && chatThreadId) {
    // if workspaceId and chatThreadId are provided, generate a link to the chat thread using the workspaceId and chatThreadId
    try {
      return new URL(
        Routes.chat(workspaceId, chatThreadId, chatResponseId),
        window.location.origin,
      ).toString();
    } catch (err) {
      console.error('Failed to generate link: ', err);
      return undefined;
    }
  } else {
    // Otherwise, generate a link based on the current URL
    const currentUrl = new URL(window.location.href);
    if (hashValue) {
      currentUrl.hash = hashValue;
    }
    return currentUrl.toString();
  }
}

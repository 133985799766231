import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  useContext,
} from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import {
  Stack,
  Tab as MuiTab,
  Tabs as MuiTabs,
  styled,
  Popper,
  ClickAwayListener,
  Card,
  Typography,
  ListItem,
  Box,
} from '@mui/material';

// components
import Icon from '../../shared/Icon';
import { IconName } from '../../shared/Icon/types';
import Avatar from '../../shared/Avatar';
import EnvironmentSelector from '../../EnvironmentSelector';
import ToggleButton from '../../shared/ToggleButton';

// hooks and more
import {
  useSessionInfo,
  useSession,
  useAppSelector,
  FeatureEnum,
  useFeatureFlags,
} from '../../../hooks';
import { EnvironmentContext } from '../../EnvironmentProvider';
import { INVENTIVE_LOGO } from '../../../utils/filePaths';
import { useColorMode } from '../../../contexts/ColorModeContext';

const Tab = styled(MuiTab)(({ theme }) => ({
  textTransform: 'none',
  minHeight: 'unset',
  padding: '16px 12px',
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
}));

type TabType = {
  label: string;
  icon?: IconName;
  hidden?: boolean;
  pathPattern: string;
  path: string;
};

interface SettingsTopBarProps {
  workspaceId?: string;
}

const SettingsTopBar = ({ workspaceId }: SettingsTopBarProps) => {
  const router = useRouter();
  const { workspace } = useAppSelector((store) => store.workspace.value);
  const storedWorkspaceId = workspace?.id;
  const workspaceIdToUse = workspaceId ?? storedWorkspaceId;
  const { isFeatureEnabled } = useFeatureFlags();
  const isCreateEnabled = isFeatureEnabled(FeatureEnum.Topics);
  const isDebuggingEnabled = isFeatureEnabled(FeatureEnum.Debugging);
  const { firstName, lastName, email, userType } = useSessionInfo();
  const { signOut } = useSession();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(anchorEl);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { environment } = useContext(EnvironmentContext);
  const { modeSetting, setColorModeSetting } = useColorMode();

  const tabs = useMemo<TabType[]>(
    () => [
      {
        label: '',
        hidden: true,
        pathPattern: '/environment',
        path: '/environment',
      }, // Tabs index cannot be undefined, so hidden tab is used.
      {
        label: 'View as',
        icon: 'workspaces',
        pathPattern: '/workspace',
        path: workspaceIdToUse
          ? `/workspace/${workspaceIdToUse}`
          : '/workspace',
      },
      {
        label: 'Manage',
        icon: 'workspace-settings',
        pathPattern: '/workspace-settings',
        path: workspaceIdToUse
          ? `/workspace-settings/${workspaceIdToUse}/general`
          : '/workspace-settings',
      },
      ...(isCreateEnabled
        ? ([
            {
              label: 'Create',
              icon: 'sparkle',
              pathPattern: '/create',
              path: '/create',
            },
          ] as TabType[])
        : ([] as TabType[])),
      {
        label: 'Tune',
        icon: 'writing',
        pathPattern: '/tune',
        path: '/tune',
      },
      {
        label: 'Settings',
        icon: 'settings',
        pathPattern: '/organization-settings',
        path: '/organization-settings',
      },
    ],
    [isCreateEnabled, workspaceIdToUse],
  );

  const goEnvironmentHome = useCallback(() => {
    router.push('/environment');
    setTabIndex(0);
  }, [router]);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      const path = tabs[newValue].path;
      if (path) {
        router.push(path);
      }
    },
    [router, tabs],
  );

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  // set the tab index based on the current path
  useEffect(() => {
    const mainPath = '/' + router.pathname.split('/')[1];
    const indexOfFoundPath = tabs.findIndex(
      (tab) => tab.pathPattern === mainPath,
    );
    if (indexOfFoundPath > -1) {
      setTabIndex(indexOfFoundPath);
    }
  }, [router, router.pathname, tabs]);

  const profileMenu = [
    {
      label: 'Sign out',
      onClick: () => {
        signOut(router);
      },
    },
  ];

  return (
    <Stack
      id='settings-top-bar'
      paddingX={3.5}
      borderBottom={(theme) => `1px solid ${theme.palette.border.light}`}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      spacing={2}
    >
      <Stack
        id='logo-container'
        alignItems='center'
        spacing={1}
        direction='row'
        sx={{
          cursor: 'pointer',
        }}
      >
        <Box
          height={24} // required to maintain the height of the logo
        >
          <Image
            src={environment?.logoUrl || INVENTIVE_LOGO} // Do not change `||` to `??` as it will not load the default logo because of the truthy value of empty string.
            alt='Logo'
            onClick={goEnvironmentHome}
            // The width and height values are dummy values,
            // but they are required to populate the width and height attributes in the img tag.
            // These will not be used as the image is styled with width: auto and height: 100%.
            width={100}
            height={100}
            style={{
              width: 'auto',
              height: '100%',
            }}
          />
        </Box>
        {userType === 'SystemUser' && <EnvironmentSelector />}
      </Stack>
      <Stack flexGrow={1} overflow='hidden'>
        <MuiTabs
          value={tabIndex}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.pathPattern}
              label={tab.label}
              iconPosition='start'
              icon={
                tab.icon && (
                  <Icon
                    name={tab.icon}
                    size='large'
                    className='MuiTab-iconWrapper'
                  />
                )
              }
              sx={
                // Hide the tab if it is marked as hidden
                // Cannot use display none
                // MUI: The `value` provided to the Tabs component is invalid.
                // The Tab with this `value` ("0") is not part of the document layout.
                // Make sure the tab item is present in the document or that it's not `display: none`.
                tab.hidden
                  ? {
                      visibility: 'hidden',
                      minWidth: 0,
                      padding: 0,
                    }
                  : {}
              }
            />
          ))}
        </MuiTabs>
      </Stack>
      <Avatar
        sourceType='text'
        source={`${firstName} ${lastName}`}
        onClick={handleOpen}
        showTooltip={false}
        active={isProfileMenuOpen}
        size='large'
        ariaLabel='Open profile menu'
        aria-expanded={isProfileMenuOpen}
        aria-haspopup='menu'
      />
      <Popper
        open={isProfileMenuOpen}
        anchorEl={anchorEl}
        placement='bottom-end'
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Card>
            <Stack
              padding={1.5}
              borderBottom={(theme) =>
                `1px solid ${theme.palette.border.light}`
              }
            >
              <Typography variant='h6' color='text.secondary'>
                {firstName} {lastName}
              </Typography>
              <Typography variant='body2' color='text.tertiary'>
                {email}
              </Typography>
            </Stack>
            {isDebuggingEnabled && (
              <Stack
                padding={1.5}
                borderBottom={(theme) =>
                  `1px solid ${theme.palette.border.light}`
                }
              >
                <ToggleButton
                  size='small'
                  selected={modeSetting}
                  handleItemClick={setColorModeSetting}
                  options={[
                    {
                      value: 'light',
                      iconName: 'sun',
                      label: 'Light',
                      tooltipText: 'Light mode',
                    },
                    {
                      value: 'system',
                      iconName: 'workspace-settings',
                      label: 'System',
                      tooltipText: 'Follow system',
                    },
                    {
                      value: 'dark',
                      iconName: 'moon',
                      label: 'Dark',
                      tooltipText: 'Dark mode',
                    },
                  ]}
                />
              </Stack>
            )}
            <Stack>
              {profileMenu.map((item) => (
                <ListItem
                  key={item.label}
                  sx={{
                    padding: 1.5,
                    cursor: 'pointer',
                  }}
                  onClick={item.onClick}
                >
                  <Typography variant='h5'>{item.label}</Typography>
                </ListItem>
              ))}
            </Stack>
          </Card>
        </ClickAwayListener>
      </Popper>
    </Stack>
  );
};

export default SettingsTopBar;

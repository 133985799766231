import {
  useState,
  useCallback,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
  SyntheticEvent,
} from 'react';
import { Stack, Typography } from '@mui/material';
import { WORKSPACE_TOP_BAR_HEIGHT } from '../../utils/dimensionsAndBreakpoints';

// components
import Icon from '../shared/Icon';
import Button from '../shared/Button';
import SearchSelectPopper from '../shared/SearchSelectPopper';

// hooks
import { FeatureEnum, useDialog, useFeatureFlags } from '../../hooks';
import { DIALOG_IDS } from '../registeredDialogs/dialogRegistry';

// contexts
import { WorkspaceContext } from '../WorkspaceProvider';
import { EnvironmentContext } from '../EnvironmentProvider';

type Option = {
  label: string;
  id: string;
};

interface WorkspaceSelectorBarProps {
  selectionCallback: (workspaceId: string) => void;
  workspaceId?: string; // Id from url param
  hideCreateButton?: boolean;
}

// when url param workspaceId is set and passed to the component, the selected workspace is set
// When user clicks on a workspace, the workspaceId is updated
// When environment changes, the selected workspace is reset
const WorkspaceSelectorBar = ({
  selectionCallback,
  workspaceId,
  hideCreateButton = false,
}: WorkspaceSelectorBarProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined,
  );
  const { environment, envWorkspaceOptions, envWorkspaceLoading } =
    useContext(EnvironmentContext);

  const environmentId = useRef(environment?.id);

  const { isFeatureEnabled } = useFeatureFlags();

  // Reset selected workspace when environment changes
  useEffect(() => {
    if (environmentId.current !== environment?.id) {
      setSelectedOption(undefined);
    }
  }, [environment?.id]);

  // Update selected workspace when workspaceId changes from url
  useEffect(() => {
    const matchedOption = envWorkspaceOptions?.find(
      (option) => option.id === workspaceId,
    );
    setSelectedOption(matchedOption);
    environmentId.current = environment?.id;
  }, [environment?.id, envWorkspaceOptions, workspaceId]);

  const { resetWorkspace } = useContext(WorkspaceContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  const handleAutoCompleteChange = useCallback(
    (_: SyntheticEvent, option?: Option | null) => {
      if (!option) {
        // when user clears the input, do nothing, keep the popup open.
        return;
      }

      handleClose();
      if (option.id === workspaceId) {
        // workspace is already selected
        return;
      }
      setSelectedOption(option);
      resetWorkspace();
      // The workspace provider will initialize the workspace
      selectionCallback(option.id);
    },
    [workspaceId, handleClose, resetWorkspace, selectionCallback],
  );

  const open = Boolean(anchorEl);

  const { showDialog } = useDialog();

  const showCreateWorkspaceDialog = useCallback(() => {
    showDialog({
      id: DIALOG_IDS.CREATE_WORKSPACE,
      title: 'Create a new workspace',
      contentProps: {},
    });
  }, [showDialog]);

  return (
    <Stack
      width='100%'
      direction='row'
      height={WORKSPACE_TOP_BAR_HEIGHT}
      alignItems='center'
      justifyContent='space-between'
      paddingY={0.5}
      paddingX={3.5}
      sx={{
        backgroundColor: 'primary.light',
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        <Icon name='show' size='small' color='icon.main' />
        <Typography variant='h6' color='text.secondary'>
          Viewing as
        </Typography>
        <Button
          variant='outlined'
          size='small'
          endIcon={<Icon name='carat-down' size='small' />}
          onClick={handleOpen}
        >
          {selectedOption?.label ?? 'Select workspace'}
        </Button>
        <SearchSelectPopper
          // autocomplete props
          options={envWorkspaceOptions ?? []}
          value={selectedOption}
          onChange={handleAutoCompleteChange}
          loading={envWorkspaceLoading}
          noOptionsText='No workspaces'
          getOptionLabel={(option) => option.label}
          //popper props
          anchorEl={anchorEl}
          open={open}
          placement='bottom-start'
          // click away listener props
          onClickAway={handleClose}
          // input base props
          placeholder='Search workspaces'
        />
      </Stack>
      {!hideCreateButton && (
        <Stack direction='row' alignItems='center' spacing={1}>
          <Button
            variant='text'
            size='small'
            startIcon={<Icon name='workspace-create' size='small' />}
            onClick={showCreateWorkspaceDialog}
            disabled={!isFeatureEnabled(FeatureEnum.CreateWorkspace)}
            disabledText='Contact Inventive to create a workspace'
          >
            Create workspace
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default WorkspaceSelectorBar;

import { ReactNode, useState } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Card,
  InputBase,
  PopperProps,
  Typography,
  InputBaseProps,
  SxProps,
  Theme,
} from '@mui/material';
import Icon from '../Icon';
import Spinner from '../Spinner';

export interface SearchSelectProps<Value>
  extends Pick<
      AutocompleteProps<Value, undefined, undefined, undefined>,
      | 'onChange'
      | 'options'
      | 'value'
      | 'loading'
      | 'noOptionsText'
      | 'getOptionLabel'
    >,
    Pick<InputBaseProps, 'placeholder'> {
  sx?: SxProps<Theme>;
}

// This is a wrapper around the MUI Autocomplete component and does search and filtering and selection
const SearchSelect = <Value,>({
  onChange,
  options,
  value,
  loading,
  noOptionsText,
  getOptionLabel, // New prop for custom label extraction
  placeholder,
  sx,
}: SearchSelectProps<Value>) => {
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  return (
    <Card
      sx={{
        ...sx,
        borderRadius: 2,
      }}
    >
      <Autocomplete
        open // This shows the option list always
        onChange={onChange}
        loading={loading}
        PopperComponent={(props: PopperProps) => (
          <Box>
            {loading ? (
              <Spinner
                sx={{
                  width: '200px',
                  height: '256px',
                }}
              />
            ) : (
              <Box>{props.children as ReactNode}</Box>
            )}
          </Box>
        )}
        options={options}
        value={value}
        inputValue={inputValue}
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <InputBase
            autoFocus
            onChange={handleInputChange}
            startAdornment={
              <Icon
                name='search'
                size='small'
                sx={{ mr: 1 }}
                color='icon.main'
              />
            }
            placeholder={placeholder}
            ref={params.InputProps.ref} // This is to ensure the input is focused and options are shown
            {...params}
          />
        )}
        renderOption={(renderProps, option, state) => {
          const { key, ...optionProps } = renderProps;
          const isSelected = state.selected;
          const optionLabel = getOptionLabel
            ? getOptionLabel(option)
            : (option as string);
          return (
            <li
              {...optionProps}
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography color='text.secondary'>{optionLabel}</Typography>
              {isSelected && (
                <Icon
                  name='check-circle'
                  size='small'
                  sx={{
                    ml: 1,
                  }}
                />
              )}
            </li>
          );
        }}
        sx={{
          '& .MuiInputBase-root': {
            padding: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.border.light}`,
            height: '40px',
          },
        }} // This overrides the default PopperComponent to position the box below the input
      />
    </Card>
  );
};

export default SearchSelect;

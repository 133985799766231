import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Routes } from '@madeinventive/core-types';

// types
import {
  useSaveChatResponseVisualizationMutation,
  WorkspaceComponentsDocument,
  SaveChatResponseVisualizationInput,
} from '../generated/types';

// hooks
import {
  useAppDispatch,
  useToast,
  useAppSelector,
  useDialog,
  useDrawer,
} from '../hooks';
import { DIALOG_IDS } from '../components/registeredDialogs/dialogRegistry';
import { DRAWER_IDS } from '../components/registeredDrawers/drawerRegistry';
import { useVisualizationContext } from '../components/Visualization/VisualizationProvider';

// slices
import { addNewComponentId } from '../store/slices/workspaceComponents';
import { getExploreInfoByWsExploreId } from '../store/slices/loadedWorkspaceExplores';

export const useVisualization = (workspaceId: string) => {
  // entity id is either chatResponseId or componentId
  const { entityId, wsExploreId, vizTitle } = useVisualizationContext();
  const { showDefaultToast, showErrorToast } = useToast();
  const router = useRouter();

  const loadedExplores = useAppSelector((store) => store.loadedExplores.value);

  const exploreInfo = getExploreInfoByWsExploreId(
    loadedExplores,
    workspaceId,
    wsExploreId ?? '',
  );

  const envExploreId = exploreInfo?.envExploreId ?? '';

  const storeDispatch = useAppDispatch();

  const [saveChatResponseVisualization] =
    useSaveChatResponseVisualizationMutation({
      refetchQueries: [
        {
          query: WorkspaceComponentsDocument,
          variables: {
            id: workspaceId,
          },
        },
      ],
    });

  const saveViz = useCallback(
    async ({ chatResponseId, name }: SaveChatResponseVisualizationInput) => {
      const result = await saveChatResponseVisualization({
        variables: {
          input: {
            chatResponseId,
            name,
          },
        },
      });

      if (result.data?.saveChatResponseVisualization.component) {
        const componentId =
          result.data.saveChatResponseVisualization.component.id;
        storeDispatch(addNewComponentId(componentId));
        showDefaultToast('Content saved to workspace.', 'View', () => {
          const path = Routes.chat(
            workspaceId,
            undefined,
            undefined,
            componentId,
          );
          router.push(path);
        });
      } else {
        showErrorToast('Failed to save content to workspace.');
      }
    },
    [
      saveChatResponseVisualization,
      storeDispatch,
      showDefaultToast,
      showErrorToast,
      workspaceId,
      router,
    ],
  );

  const { showDrawer } = useDrawer();

  const showScheduleReportDrawer = useCallback(() => {
    showDrawer({
      id: DRAWER_IDS.CREATE_ALERT,
      title: 'Create alert', // use the same title as the create alert drawer
      contentProps: {
        isDrawer: true,
        workspaceId,
        envExploreId,
        vizTitle,
        entityId,
        startingStep: 'schedule',
      },
    });
  }, [workspaceId, envExploreId, vizTitle, entityId, showDrawer]);

  const showCreateAlertDrawer = useCallback(() => {
    showDrawer({
      id: DRAWER_IDS.CREATE_ALERT,
      title: 'Create alert',
      contentProps: {
        isDrawer: true,
        workspaceId,
        envExploreId,
        vizTitle,
        entityId,
        startingStep: 'condition',
      },
    });
  }, [workspaceId, envExploreId, vizTitle, entityId, showDrawer]);

  const { showDialog } = useDialog();

  const showSaveToCreateDialog = useCallback(() => {
    showDialog({
      id: DIALOG_IDS.SAVE_TO_CREATE,
      title: 'Save to Create (admins only)',
      contentProps: {
        entityId,
      },
    });
  }, [showDialog, entityId]);

  const showSaveToWorkspaceDialog = useCallback(
    (defaultTitle?: string) => {
      showDialog({
        id: DIALOG_IDS.SAVE_TO_WORKSPACE,
        title: 'Save to workspace',
        contentProps: {
          defaultTitle,
          onSubmit: (name) => saveViz({ chatResponseId: entityId, name: name }),
        },
      });
    },
    [showDialog, saveViz, entityId],
  );

  return {
    saveViz,
    showScheduleReportDrawer,
    showCreateAlertDrawer,
    showSaveToCreateDialog,
    showSaveToWorkspaceDialog,
  };
};

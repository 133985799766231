import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

// mui components
import {
  Box,
  Divider,
  Typography,
  InputLabel,
  IconButton,
} from '@mui/material';

// icons
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import EditIcon from '@mui/icons-material/Edit';

// components
import Button from '../../shared/Button';
import TemplateSidebar from './TemplateSidebar';
import Templates from '../../Templates';

import {
  FeatureTemplate,
  FeatureType,
  PageInfo,
  PaginationArgs,
  useFeatureTemplateListLazyQuery,
} from '../../../generated/types';
import PaginationControl from '../../PaginationControl';

import ActionConfigPreview from './ActionConfigPreview';
import DisabledBox from './DisabledBox';
import ConditionList from '../../ConditionForm/ConditionList';
import TreeView, { TreeViewItem } from './TreeView';
import EditTemplateCategory from '../EditTemplateCategory';
import {
  useSessionInfo,
  useTemplateCategories,
  parseFromConfig,
  LoadSource,
  ALL_TEMPLATES,
  UNCATEGORIZED,
} from '../../../hooks';
import Spinner from '../../shared/Spinner';
import moment from 'moment';
import Avatar from '../../shared/Avatar';
import { FeatureEditDataState } from '../../../store/slices/features';
import { ACTION_MANIFEST_LOOKUP } from '../../../hooks/feature/ActionManifest';

export const NEW_FEATURE_FROM_TEMPLATE_ID = 'new-from-template';

interface TemplateGalleryModalProps {
  galleryPage: string;
  setGalleryPage: (params: string) => void;
  closeTemplateGalleryModal: () => void;
  setOpen: (params: boolean) => void;
  closeModal: () => void;
}

const TemplateGalleryModal = ({
  galleryPage,
  setGalleryPage,
  closeTemplateGalleryModal,
  setOpen,
  closeModal,
}: TemplateGalleryModalProps) => {
  const RECORDS_PER_PAGE = 9;
  const sidebarHeader = 'Categories';
  const [selectedTemplate, setSelectedTemplate] = useState<FeatureTemplate>();
  const router = useRouter();

  const { workspaceId } = router.query;
  const { userType } = useSessionInfo();

  const [editCategory, setEditCategory] = useState(false);
  const [page, setPage] = useState(1);

  const {
    categoryGroupings,
    getCategoryGroupingById,
    isCategoryGroupingEditable,
  } = useTemplateCategories();

  let pageInfo: PageInfo = {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  let totalRecords = 0;
  const paginationArgs: PaginationArgs = { first: RECORDS_PER_PAGE };
  const [selectedSidebarItemId, setSelectedSidebarItemId] = useState(
    ALL_TEMPLATES.id,
  );
  const [fetchTemplates, { data, loading }] = useFeatureTemplateListLazyQuery({
    variables: { params: { pagination: paginationArgs } },
    notifyOnNetworkStatusChange: true,
  });

  const refreshTemplates = () => {
    if (selectedSidebarItemId === ALL_TEMPLATES.id) {
      fetchTemplates();
    } else if (selectedSidebarItemId === UNCATEGORIZED.id) {
      fetchTemplates({
        variables: {
          params: {
            pagination: paginationArgs,
            categoryId: null,
          },
        },
      });
    } else {
      fetchTemplates({
        variables: {
          params: {
            pagination: paginationArgs,
            categoryId: selectedSidebarItemId,
          },
        },
      });
    }
  };

  // @TODO: verify, this really looks like a hack
  useEffect(() => {
    refreshTemplates();
  }, [selectedSidebarItemId]); //eslint-disable-line

  let templates: FeatureTemplate[] = [];

  if (data?.viewer.user?.__typename === 'EnvironmentMember') {
    templates = data?.viewer.user.environment.featureTemplates.edges.map(
      (edge) => edge.node as FeatureTemplate,
    );
    pageInfo = data?.viewer.user.environment.featureTemplates.pageInfo || {};
    totalRecords =
      data?.viewer.user.environment.featureTemplates.totalCount || 0;
  }

  // @TODO: currently this grabs only environment-level templates because we don't have UI for workspace ones yet
  if (data?.viewer.user?.__typename === 'WorkspaceMember') {
    templates =
      data?.viewer.user.workspace.environment.featureTemplates.edges.map(
        (edge) => edge.node as FeatureTemplate,
      );
    pageInfo =
      data?.viewer.user.workspace.environment.featureTemplates.pageInfo || {};
    totalRecords =
      data?.viewer.user.workspace.environment.featureTemplates.totalCount || 0;
  }

  const createNewTemplate = useCallback(() => {
    router.push(`/workspace/${workspaceId}/features/new`);
    closeModal();
  }, [closeModal, router, workspaceId]);

  const numPages = useMemo(
    () =>
      Math.floor(totalRecords / RECORDS_PER_PAGE) +
      (totalRecords % RECORDS_PER_PAGE > 0 ? 1 : 0),
    [totalRecords],
  );

  useEffect(() => {
    if (!loading && page > numPages) {
      setPage(numPages || 1);
    }
  }, [page, numPages, loading]);

  const handleNextPage = () => {
    setPage((prev) => (prev < numPages ? prev + 1 : prev));
    const customParams: {
      pagination: { first: number; after?: string | null };
      categoryId?: string | null;
    } = {
      pagination: { first: RECORDS_PER_PAGE, after: pageInfo?.endCursor },
    };

    if (selectedSidebarItemId === UNCATEGORIZED.id) {
      customParams['categoryId'] = null;
    } else if (selectedSidebarItemId !== ALL_TEMPLATES.id) {
      customParams['categoryId'] = selectedSidebarItemId;
    }
    fetchTemplates({
      variables: {
        params: customParams,
      },
    });
  };

  const handlePreviousPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
    const customParams: {
      pagination: { last: number; before?: string | null };
      categoryId?: string | null;
    } = {
      pagination: { last: RECORDS_PER_PAGE, before: pageInfo?.startCursor },
    };

    if (selectedSidebarItemId === UNCATEGORIZED.id) {
      customParams['categoryId'] = null;
    } else if (selectedSidebarItemId !== ALL_TEMPLATES.id) {
      customParams['categoryId'] = selectedSidebarItemId;
    }

    fetchTemplates({
      variables: {
        params: customParams,
      },
    });
  };
  // First Page
  const dialogFooter = () => {
    return (
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ px: 3, py: 1 }}
      >
        {userType === 'EnvironmentMember' && (
          <Button variant='text' onClick={createNewTemplate}>
            Create new template
          </Button>
        )}
        <Box sx={{ display: 'flex' }} />
        {data && (
          <PaginationControl
            totalRecords={totalRecords}
            recordsPerPage={RECORDS_PER_PAGE}
            page={page}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
            hasPreviousPage={pageInfo.hasPreviousPage}
            hasNextPage={pageInfo.hasNextPage}
            loading={loading}
          />
        )}
      </Box>
    );
  };

  const renderSelectedCategory = useCallback(() => {
    const categoryItem = getCategoryGroupingById(selectedSidebarItemId);
    return (
      <Typography
        sx={{
          display: 'flex',
          lineHeight: '28px',
          fontWeight: 700,
        }}
        variant='h5'
        flexDirection='row'
        alignItems='center'
      >
        {categoryItem.name}
        {userType === 'EnvironmentMember' &&
          isCategoryGroupingEditable(selectedSidebarItemId) && (
            <IconButton sx={{ ml: 1 }} onClick={() => setEditCategory(true)}>
              <EditIcon
                sx={{
                  fontSize: (theme) => theme.typography.h5.fontSize,
                }}
              />
            </IconButton>
          )}
      </Typography>
    );
  }, [
    getCategoryGroupingById,
    isCategoryGroupingEditable,
    selectedSidebarItemId,
    setEditCategory,
    userType,
  ]);

  const renderTemplateCards = () => {
    return (
      <>
        {isCategoryGroupingEditable(selectedSidebarItemId) && (
          <EditTemplateCategory
            open={editCategory}
            setOpen={setEditCategory}
            category={getCategoryGroupingById(selectedSidebarItemId)}
          />
        )}
        <Box display='flex'>
          <Box
            sx={{
              width: '250px',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                overflowY: 'scroll',
              }}
            >
              <TemplateSidebar
                setOpen={setOpen}
                sidebarItems={categoryGroupings}
                sidebarHeader={sidebarHeader}
                selectedSidebarItemId={selectedSidebarItemId}
                setSelectedSidebarItemId={setSelectedSidebarItemId}
                userType={userType}
              />
            </Box>
          </Box>
          <Box
            width='calc(100% - 250px)'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
          >
            <Box sx={{ pt: '30px', px: '20px', minHeight: '530px' }}>
              {renderSelectedCategory()}
              <Box
                sx={{
                  display: 'grid',
                  minHeight: '460px',
                }}
              >
                {loading ? (
                  <Box sx={{ alignSelf: 'center', justifySelf: 'center' }}>
                    <Spinner />
                  </Box>
                ) : templates.length > 0 ? (
                  <Box>
                    <Templates
                      fetchTemplates={refreshTemplates}
                      templatesData={templates}
                      setGalleryPage={setGalleryPage}
                      setSelectedTemplate={setSelectedTemplate as () => void}
                    />
                  </Box>
                ) : (
                  <Box sx={{ alignSelf: 'center', justifySelf: 'center' }}>
                    <Typography
                      variant='h5'
                      sx={{
                        color: 'text.secondary',
                        lineHeight: '28px',
                      }}
                    >
                      You don’t have any templates yet
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box>
              <Divider />
              {dialogFooter()}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const handleUseTemplate = useCallback(() => {
    closeTemplateGalleryModal();
    if (selectedTemplate) {
      router.push(
        `/workspace/${workspaceId}/features/${NEW_FEATURE_FROM_TEMPLATE_ID}?templateId=${selectedTemplate.id}`,
      );
    }
  }, [closeTemplateGalleryModal, workspaceId, selectedTemplate, router]);

  const renderTemplateDetails = useCallback(
    (template: FeatureTemplate | undefined) => {
      if (!template) return <></>;

      const parsedConfig = parseFromConfig(
        LoadSource.TEMPLATE,
        FeatureType.WORKFLOW,
        template.configJSON,
      );

      const getV2TreeViewItems = () => {
        const items: TreeViewItem[] = [
          {
            icon: <AutorenewIcon fontSize='small' sx={{ display: 'inline' }} />,
            label: 'Automate work with alerts and actions',
          },
          {
            icon: (
              <InventoryOutlinedIcon
                fontSize='small'
                sx={{ display: 'inline' }}
              />
            ),
            label: 'When a record matches conditions',
          },
        ];

        parsedConfig.actions.forEach((a) => {
          const manifest = ACTION_MANIFEST_LOOKUP[a.kind];
          if (manifest) {
            items.push({
              icon: manifest.cardProps.icon('small'),
              label: manifest.cardProps.title,
            });
          }
        });
        return items;
      };

      const getNonEmptyConfigFields = (cfg: object) =>
        Object.fromEntries(Object.entries(cfg).filter(([, v]) => v != ''));

      const renderTrigger = (
        triggerConditions: Pick<FeatureEditDataState, 'conditions'>,
      ) => {
        return (
          <ConditionList
            conditions={triggerConditions.conditions}
            inputsDisabled={true}
          />
        );
      };

      const renderActionTitle = (title: string) => (
        <DisabledBox key={title}>
          <AutorenewIcon fontSize='small' sx={{ display: 'inline' }} />
          <Typography>{title}</Typography>
        </DisabledBox>
      );

      const renderV2Actions = (
        parsedAction: Pick<FeatureEditDataState, 'actions'>,
      ) => {
        const nodes: ReactNode[] = [];
        parsedAction.actions.forEach((a, index) => {
          const manifest = ACTION_MANIFEST_LOOKUP[a.kind];
          if (manifest && manifest.controlConfig) {
            nodes.push(renderActionTitle(manifest.cardProps.title));
            nodes.push(
              <ActionConfigPreview
                sections={getNonEmptyConfigFields(a.spec)}
                controlConfig={manifest.controlConfig}
                key={`action_${index}`}
              />,
            );
          }
        });
        return <>{nodes}</>;
      };

      return (
        <Box display='flex'>
          <Box
            px={2}
            py={4}
            sx={{ position: 'relative', width: '70%', minHeight: '500px' }}
          >
            <Box
              p={4}
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                overflowY: 'scroll',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  variant='h3'
                  component='h1'
                  sx={{
                    fontWeight: 600,
                    mb: 3,
                  }}
                  noWrap
                >
                  {template.name}
                </Typography>
              </Box>
              <Box display='flex' flexDirection='column'>
                <Box>
                  <InputLabel>
                    <Typography
                      variant='h5'
                      component='h3'
                      color='text.primary'
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      Objective
                    </Typography>
                  </InputLabel>
                  <DisabledBox>
                    <AutorenewIcon
                      fontSize='small'
                      sx={{ display: 'inline' }}
                    />
                    <Typography>
                      Automate work with alerts and actions
                    </Typography>
                  </DisabledBox>
                  <Divider sx={{ my: 1 }} />
                </Box>
                <Box mt={1}>
                  <InputLabel>
                    <Typography
                      variant='h5'
                      component='h3'
                      color='text.primary'
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      Trigger
                    </Typography>
                  </InputLabel>
                  <DisabledBox>
                    <AutorenewIcon
                      fontSize='small'
                      sx={{ display: 'inline' }}
                    />
                    <Typography>When a record matches conditions</Typography>
                  </DisabledBox>
                  {renderTrigger(parsedConfig)}
                  <Divider sx={{ my: 1 }} />
                </Box>
                <Box mt={1}>
                  <InputLabel>
                    <Typography
                      variant='h5'
                      component='h3'
                      color='text.primary'
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      Action
                    </Typography>
                  </InputLabel>
                  {renderV2Actions(parsedConfig)}
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider orientation='vertical' flexItem={true} />
          <Box p={4} flex={1}>
            <Box
              sx={{ width: '90%', mx: 'auto', minHeight: '100%' }}
              display='flex'
              gap={1}
              flexDirection='column'
            >
              {template?.createdBy && (
                <Box display='flex' gap={2} mb={2}>
                  <Avatar
                    sourceType='text'
                    source={template.createdBy.firstname}
                  />
                  <Box>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: 'primary.dark',
                      }}
                    >
                      Created by {template.createdBy.firstname}{' '}
                      {template.createdBy.lastname}
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: 'primary.light',
                      }}
                    >
                      Last updated {moment(template?.updatedAt).fromNow()}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box mb={2}>
                <Typography
                  variant='body2'
                  component='h5'
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    mb: 1,
                  }}
                >
                  DESCRIPTION
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    fontWeight: 400,
                    lineHeight: '14px',
                    color: 'text.primary',
                  }}
                >
                  {template.description}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  component='h4'
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    mb: 1,
                  }}
                >
                  INCLUDES
                </Typography>
                <Box display='flex' flexDirection='column' gap={1} mb={0.5}>
                  <TreeView items={getV2TreeViewItems()} />
                </Box>
              </Box>
              <Button variant='contained' onClick={handleUseTemplate}>
                Use template
              </Button>
            </Box>
          </Box>
        </Box>
      );
    },
    [handleUseTemplate],
  );

  return galleryPage === 'firstPage'
    ? renderTemplateCards()
    : renderTemplateDetails(selectedTemplate);
};

export default TemplateGalleryModal;

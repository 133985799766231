import * as yup from 'yup';
import { SchemaNode } from '../../store/slices/exploreExtracts';
import {
  EmailAttachmentV1,
  DynamicFieldV1,
  NormalizedConditionValueV1,
  NormalizedOperatorV1,
} from '@madeinventive/core-types';

export type ActionTriggerType = 'condition' | 'schedule';

export type MultiStepFormValues = {
  settings: SettingsFormValues;
  message: MessageFormValues;
};

export type ConditionFormValues = {
  variable?: DynamicFieldV1;
  operator?: NormalizedOperatorV1;
  value?: NormalizedConditionValueV1;
  chainingOperator: 'AND';
  isEditing: boolean;
};

export interface SettingsFormValues {
  actionTriggerType?: ActionTriggerType;
  alertsAbout?: SchemaNode;
  attachment?: EmailAttachmentV1;
  cron?: string;
  conditions?: ConditionFormValues[];
}

export const settingsInitialValues: SettingsFormValues = {
  actionTriggerType: undefined,
  alertsAbout: undefined,
  cron: undefined,
  conditions: [
    {
      variable: undefined,
      operator: undefined,
      value: undefined,
      chainingOperator: 'AND',
      isEditing: true,
    },
  ],
};

export const settingsValidationSchema = yup.object({
  actionTriggerType: yup.string().oneOf(['schedule', 'condition']).required(),
  cron: yup.string().when('actionTriggerType', {
    is: 'schedule',
    then: yup.string().required('Please select a schedule.'),
  }),
  alertsAbout: yup.object().when('actionTriggerType', {
    is: 'condition',
    then: yup.object().required('Please choose what to be alerted.'),
  }),
  conditions: yup.array().when('actionTriggerType', {
    is: 'condition',
    then: yup
      .array()
      .of(
        yup.object({
          variable: yup.object().required('Please choose a data field.'),
          operator: yup.object().required('Please choose an operator.'),
          value: yup.object().when('operator', {
            is: (operator?: NormalizedOperatorV1) =>
              operator && operator.isUnary === false,
            then: yup.object().required('Please fill in at least one value.'),
          }),
        }),
      )
      .required('Please add at least one condition.')
      .min(1, 'Please add at least one condition.'),
  }),
});

// Message
export enum ActionType {
  Email = 'Email',
  Slack = 'Slack',
}

export interface MessageFormValues {
  actionType?: ActionType;
  recipients?: string[];
  emailBody?: string;
  emailSubject?: string; // advanced
  buttonText?: string; // advanced
  buttonLink?: string; // advanced
  slackChannelIds?: string[]; // slack
  slackMessage?: string; // slack
}

export const messageInitialValues: MessageFormValues = {
  actionType: ActionType.Email,
  recipients: [],
  emailBody: '',
  emailSubject: '', // advanced
  buttonText: '', // advanced
  buttonLink: '', // advanced
  slackChannelIds: [], // slack
  slackMessage: '', // slack
};

// Helper function to validate HTML content using DOMParser
const isValidHtml = (html?: string) => {
  if (!html) return false;
  try {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const body = doc.body;
    // Check if the body content is just an empty paragraph or empty
    return (
      body && !(body.innerHTML === '<p></p>' || body.textContent?.trim() === '')
    );
  } catch (e) {
    return false;
  }
};

export const messageValidationSchema = yup.object({
  actionType: yup
    .string()
    .oneOf([ActionType.Email, ActionType.Slack])
    .required('Please choose how to be notified.'),
  recipients: yup.array().when('actionType', {
    is: ActionType.Email,
    then: yup
      .array()
      .min(1, 'Please enter at least one recipient email address.'),
  }),
  emailBody: yup.string().when('actionType', {
    is: ActionType.Email,
    then: yup
      .string()
      .test('is-valid-html', 'Invalid HTML content', (value) => {
        return isValidHtml(value);
      })
      .required('Please fill in message.'),
  }),
  emailSubject: yup.string().when('actionType', {
    is: ActionType.Email,
    then: yup.string().required('Please fill in email subject.'),
  }),
  buttonText: yup.string().when('actionType', {
    is: ActionType.Email,
    then: yup.string().required('Please fill in button text.'),
  }),
  buttonLink: yup.string().when('actionType', {
    is: ActionType.Email,
    then: yup.string().required('Please fill in button link.'),
  }),
  slackChannelIds: yup.array().when('actionType', {
    is: ActionType.Slack,
    then: yup.array().min(1, 'Please fill in channel ID.'),
  }),
  slackMessage: yup.string().when('actionType', {
    is: ActionType.Slack,
    then: yup.string().required('Please enter a message.'),
  }),
});

export const DYNAMIC_FIELD_SUB_LABEL =
  'Dynamic content at your fingertips! Type "{" or use the menu to add referencing specific data fields. These tags will be replaced with current values upon sending.';

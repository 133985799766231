import { useState, MouseEvent, useMemo } from 'react';
import {
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  Popper,
  Paper,
  ClickAwayListener,
  PopperProps,
  Box,
} from '@mui/material';
import IconButton from '../IconButton';
import Icon from '../Icon';
import { IconName } from '../Icon/types';
import Button, { ButtonProps } from '../Button';

export type MenuItemType = {
  id: string;
  iconName?: IconName;
  text: string;
  onClick: () => void;
  hidden?: boolean;
  disabled?: boolean;
  divider?: boolean;
  group?: string;
};

interface MenuProps {
  id: string;
  buttonText?: string;
  buttonIcon?: IconName;
  buttonVariant?: 'text' | 'contained' | 'outlined';
  placement?: PopperProps['placement'];
  menuItems: Array<MenuItemType>;
}
const Menu = ({
  id,
  buttonText,
  buttonIcon,
  buttonVariant = 'text',
  placement = 'bottom-end',
  menuItems,
}: MenuProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const coreButtonProps: Partial<ButtonProps> = useMemo(
    () => ({
      variant: buttonVariant,
      size: 'small',
      id: `${id}-button`,
      'aria-controls': menuOpen ? 'basic-menu' : undefined,
      'aria-haspopup': 'true',
      'aria-expanded': menuOpen ? 'true' : undefined,
      onClick: handleMenuButtonClick,
    }),
    [buttonVariant, id, menuOpen],
  );

  const groupedMenuItems = useMemo(() => {
    const groups = menuItems.reduce((acc, item) => {
      const group = item.group || '';
      if (!acc[group]) {
        acc[group] = [];
      }
      if (!item.hidden) {
        acc[group].push(item);
      }
      return acc;
    }, {} as Record<string, MenuItemType[]>);

    // Filter out empty groups
    return Object.keys(groups)
      .filter((group) => groups[group].length > 0)
      .map((group) => ({
        label: group,
        items: groups[group],
      }));
  }, [menuItems]);

  return (
    <>
      {buttonText ? (
        <Button
          {...coreButtonProps}
          title={buttonText}
          startIcon={buttonIcon ? <Icon name={buttonIcon} /> : undefined}
        />
      ) : (
        <IconButton
          {...coreButtonProps}
          iconName={buttonIcon ?? 'more-horizontal'}
        />
      )}
      <Popper
        container={document.body}
        open={menuOpen}
        anchorEl={menuAnchorEl}
        placement={placement}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
        // In some cases, especially when the parent has absolute positioning,
        // the popper might not show up because of the stacking context
        // This is to ensure that the popper is above the modal
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>
          <Paper
            elevation={2}
            sx={(theme) => ({
              border: `1px solid ${theme.palette.border.light}`,
              borderRadius: 2,
            })}
          >
            {groupedMenuItems.map((group) => (
              <Box key={group.label}>
                {/* show group name only when there are multiple groups */}
                {groupedMenuItems.length > 1 && group.label.trim() && (
                  <Box p={1}>
                    <Typography
                      variant='caption'
                      color='text.secondary'
                      sx={(theme) => ({
                        fontWeight: theme.typography.fontWeightMedium,
                      })}
                    >
                      {group.label}
                    </Typography>
                  </Box>
                )}
                {group.items.map((item) => (
                  <Item
                    key={item.id}
                    item={item}
                    handleMenuClose={handleMenuClose}
                  />
                ))}
              </Box>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Menu;

const Item = ({
  item,
  handleMenuClose,
}: {
  item: MenuItemType;
  handleMenuClose: () => void;
}) => {
  if (item.hidden) return null;
  return (
    <>
      {item.divider && (
        <Divider
          key={`${item.id}-divider`}
          // resets default margin
          sx={{
            '&.MuiDivider-root': {
              marginTop: 0,
              marginBottom: 0,
            },
          }}
        />
      )}
      <MenuItem
        id={`menu-item-${item.id}`}
        key={item.id}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          item.onClick();
          handleMenuClose();
        }}
        hidden={item.hidden}
        disabled={item.disabled}
        sx={{
          padding: 1,
          '&:hover': {
            backgroundColor: 'background.default',
          },
        }}
      >
        {item.iconName && (
          <ListItemIcon
            sx={{
              '&.MuiListItemIcon-root': {
                minWidth: 'unset',
              },
              marginRight: 1,
            }}
          >
            <Icon name={item.iconName} size='small' color='text.secondary' />
          </ListItemIcon>
        )}
        <Typography variant='body1' color='text.secondary'>
          {item.text}
        </Typography>
      </MenuItem>
    </>
  );
};

import { useCallback, useState, useMemo } from 'react';
import { Stack } from '@mui/material';

// components
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import ToggleChips, { ChipOptionType } from '../shared/ToggleChips';
import DialogActions from '../shared/Dialog/DialogActions';

// hooks and context
import {
  useAppDispatch,
  useAppSelector,
  useFeatureFlags,
  FeatureEnum,
} from '../../hooks';
import {
  updateChatConfig,
  ChatConfig,
  GPT4_OMNI_2024_08_06,
  GPT4_TURBO,
  O1_MINI_2024_09_12_MODEL,
  O1_2024_12_17_MODEL,
  O3_MINI_2025_01_31,
  GPT4_OMNI_2024_11_20,
  CLAUDE_3_5_SONNET_LATEST,
  CLAUDE_3_5_HAIKU_LATEST,
  CLAUDE_3_OPUS_LATEST,
  GEMINI_1_5_FLASH,
  GEMINI_1_5_PRO,
  GEMINI_2_0_FLASH_EXP,
  DEEPSEEK_CHAT,
  DEEPSEEK_REASONER,
  DEEPSEEK_CODER,
  AZURE_GPT_4O,
  GROQ_LLAMA_3_3_70B_VERSATILE,
  GROQ_LLAMA_3_2_90B_VISION_PREVIEW,
} from '../../store/slices/workspace';
import { setDataSourceId } from '../../store/slices/currentChat';

// utils
import { AUTOMATIC_EXPLORE_VALUE } from '../WorkspaceChat/constants';
import { BaseRegisteredDialogComponentProps } from './types';
import {
  chatSettingOptions,
  CHAT_SETTING_VALUES,
  getSelectedChatSetting,
} from '../../utils/chatSettings';

export const MODEL_OPTIONS: ChipOptionType[] = [
  { value: GPT4_TURBO, label: 'GPT-4 Turbo 04-09' },
  { value: GPT4_OMNI_2024_08_06, label: 'GPT-4o 08-06' },
  { value: GPT4_OMNI_2024_11_20, label: 'GPT-4o 11-20' },
  { value: O1_MINI_2024_09_12_MODEL, label: 'O1 Mini 09-12' },
  { value: O1_2024_12_17_MODEL, label: 'O1 12-17' },
  { value: O3_MINI_2025_01_31, label: 'O3 Mini 01-31' },
  { value: CLAUDE_3_5_SONNET_LATEST, label: 'Claude 3.5 Sonnet Latest' },
  { value: CLAUDE_3_5_HAIKU_LATEST, label: 'Claude 3.5 Haiku Latest' },
  { value: CLAUDE_3_OPUS_LATEST, label: 'Claude 3 Opus Latest' },
  { value: GEMINI_1_5_FLASH, label: 'Gemini 1.5 Flash' },
  { value: GEMINI_1_5_PRO, label: 'Gemini 1.5 Pro' },
  { value: GEMINI_2_0_FLASH_EXP, label: 'Gemini 2.0 Flash Exp' },
  { value: DEEPSEEK_CHAT, label: 'DeepSeek Chat' },
  { value: DEEPSEEK_REASONER, label: 'DeepSeek Reasoner' },
  { value: DEEPSEEK_CODER, label: 'DeepSeek Coder' },
  { value: AZURE_GPT_4O, label: 'Azure GPT-4o' },
  {
    value: GROQ_LLAMA_3_3_70B_VERSATILE,
    label: 'Groq Llama 3.3 70B Versatile',
  },
  {
    value: GROQ_LLAMA_3_2_90B_VISION_PREVIEW,
    label: 'Groq Llama 3.2 90B Vision Preview',
  },
];

export interface ChatSettingsProps extends BaseRegisteredDialogComponentProps {}

const ChatSettings = ({ hideDialog }: BaseRegisteredDialogComponentProps) => {
  const storeDispatch = useAppDispatch();
  const { isFeatureEnabled } = useFeatureFlags();
  const { chatConfig } = useAppSelector((store) => store.workspace.value);
  const { dataSourceId } = useAppSelector((store) => store.currentChat.value);
  const { dataSourceOptions } = useAppSelector(
    (store) => store.workspace.value,
  );

  const [selectedDataSourceId, setSelectedDataSourceId] = useState<string>(
    dataSourceId ?? AUTOMATIC_EXPLORE_VALUE,
  );

  const [selectedModel, setSelectedModel] = useState<string>(chatConfig.model);

  const initialSelectedSettings = useMemo(() => {
    if (isFeatureEnabled(FeatureEnum.ChatConfiguration)) {
      return getSelectedChatSetting(chatConfig);
    }
    return [];
  }, [chatConfig, isFeatureEnabled]);

  const [selectedSettings, setSelectedSettings] = useState<string[]>(
    initialSelectedSettings,
  );

  // handlers
  const handleDataSourceClick = useCallback((value: string) => {
    // update local state only
    setSelectedDataSourceId(value);
  }, []);

  const handleSettingItemClick = useCallback((value: string) => {
    // update local state only
    const key = chatSettingOptions.find(
      (option) => option.value === value,
    )?.configKey;
    if (key) {
      setSelectedSettings((prev) => {
        if (prev.includes(value)) {
          return prev.filter((v) => v !== value);
        }
        return [...prev, value];
      });
    }
  }, []);

  const handleModelClick = useCallback((value: string) => {
    setSelectedModel(value);
  }, []);

  const handleSubmit = useCallback(() => {
    // update data source
    storeDispatch(setDataSourceId(selectedDataSourceId));

    // update internal settings
    if (isFeatureEnabled(FeatureEnum.ChatConfiguration)) {
      const newConfig: ChatConfig = {
        ...chatConfig,
        model: selectedModel,
        enableQuickFollow: selectedSettings.includes(
          CHAT_SETTING_VALUES.QUICK_FOLLOW,
        ),
        enableCommonQuestionHints: selectedSettings.includes(
          CHAT_SETTING_VALUES.COMMON_QUESTION_HINTS,
        ),
        enableEmbeddings: selectedSettings.includes(
          CHAT_SETTING_VALUES.DATA_EMBEDDINGS,
        ),
        enableQuestionEmbeddings: selectedSettings.includes(
          CHAT_SETTING_VALUES.QUESTION_EMBEDDINGS,
        ),
      };

      storeDispatch(updateChatConfig(newConfig));
    }
  }, [
    chatConfig,
    isFeatureEnabled,
    selectedDataSourceId,
    selectedModel,
    selectedSettings,
    storeDispatch,
  ]);

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <FormFieldLabel text='Data source' />
        <ToggleChips
          options={dataSourceOptions ?? []}
          selectedValues={
            selectedDataSourceId
              ? [selectedDataSourceId]
              : [AUTOMATIC_EXPLORE_VALUE]
          }
          handleItemClick={handleDataSourceClick}
          flexWrap
        />
      </Stack>

      {/* Internal settings */}
      {isFeatureEnabled(FeatureEnum.ChatConfiguration) && (
        <Stack spacing={1}>
          <FormFieldLabel text='Model (internal)' />
          <ToggleChips
            options={MODEL_OPTIONS}
            selectedValues={[selectedModel]}
            handleItemClick={handleModelClick}
            flexWrap
          />
        </Stack>
      )}
      {isFeatureEnabled(FeatureEnum.ChatConfiguration) && (
        <Stack spacing={1}>
          <FormFieldLabel text='Other Internal settings' />
          <ToggleChips
            options={chatSettingOptions.filter(
              (opt) => opt.configKey !== 'model',
            )}
            selectedValues={selectedSettings}
            handleItemClick={handleSettingItemClick}
            flexWrap
          />
        </Stack>
      )}

      <DialogActions
        closeDialog={hideDialog}
        secondaryAction={{
          text: 'Cancel',
          action: hideDialog,
          disabled: false,
        }}
        primaryAction={{
          text: 'Apply',
          action: handleSubmit,
          disabled: false,
        }}
      />
    </Stack>
  );
};

export default ChatSettings;

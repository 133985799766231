import { useCallback, useState } from 'react';
import moment from 'moment';

import Box, { BoxProps } from '@mui/material/Box';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import MenuButton, { MenuItemOption } from '../../MenuButton';
import Avatar, { AVATAR_DIMENSIONS } from '../Avatar';

import { CommentData, Commenter } from './types';

const iconSize = AVATAR_DIMENSIONS['small'].iconSize;
const gridColumnTemplateStr = `${iconSize}px repeat(1, 1fr) ${iconSize}px`;

const toFullname = (commenter: Commenter) => {
  const { firstname, lastname } = commenter;
  if (firstname && lastname) {
    return `${commenter.firstname} ${commenter.lastname}`;
  } else if (firstname) {
    return firstname;
  } else if (lastname) {
    return lastname;
  }
  return 'Unknown';
};

// This function convert dates into more readable human form relative
// from today. e.g. Today, Tommorrow, Yesterday, etc.
const getRelativeDayFormat = (dateStr: string | undefined) => {
  if (!dateStr) return;
  const fromNow = moment(dateStr).fromNow();
  return moment(dateStr).calendar(null, {
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    sameElse: function () {
      return '[' + fromNow + ']';
    },
  });
};

export interface CommentProps {
  data: CommentData;
  onClick?: BoxProps['onClick'];
  menuItemOptions?: MenuItemOption[];
  selected?: boolean;
  highlightBorderOnHover?: boolean;
  highlightBackgroundOnHover?: boolean;
}

const Comment = (props: CommentProps) => {
  const {
    data: { id, commenter, comment, createdAt },
  } = props;

  const [hovered, setHovered] = useState(false);
  const resetHovered = useCallback(() => setHovered(false), [setHovered]);

  return (
    <Box
      key={id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={props.onClick}
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: props.selected
          ? 'info.main'
          : props.highlightBorderOnHover && hovered
          ? 'info.main'
          : 'rgba(255, 255, 255, 0)',
        display: 'grid',
        gridTemplateColumns: gridColumnTemplateStr,
        gridColumnGap: (theme) => theme.spacing(1),
        gridRowGap: (theme) => theme.spacing(1),
        gridTemplateAreas: `
          "avatar header action"
          ". comment comment"
        `,
        p: 1,
        backgroundColor:
          props.highlightBackgroundOnHover && hovered
            ? 'secondary.main'
            : undefined,
      }}
    >
      <Box sx={{ gridArea: 'avatar' }}>
        <Avatar sourceType='text' source={toFullname(commenter)} size='small' />
      </Box>
      <Stack
        sx={{ gridArea: 'header' }}
        alignItems='center'
        direction='row'
        spacing={1}
        flexShrink={1}
      >
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          {toFullname(commenter)}
        </Typography>
        {createdAt && (
          <Typography
            variant='body2'
            sx={{ color: 'text.secondary', fontStyle: 'italic' }}
          >
            {getRelativeDayFormat(createdAt)}
          </Typography>
        )}
      </Stack>
      {props.menuItemOptions && props.menuItemOptions.length > 0 && hovered && (
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          sx={{ gridArea: 'action' }}
        >
          <MenuButton
            menuItemOptions={props.menuItemOptions}
            buttonProps={{ sx: { p: 0, minWidth: 0 } }}
            onMenuClose={resetHovered}
          >
            <MoreVertIcon />
          </MenuButton>
        </Box>
      )}
      {!!comment && (
        <Box sx={{ gridArea: 'comment' }}>
          <Typography variant='body1'>{comment || ' '}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Comment;

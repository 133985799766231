export const USER_STATUS_MAP: { [key: string]: string } = {
  PENDING: 'Pending',
  INVITED: 'Invited',
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
};

export const WORKSPACE_USER_ROLE_MAP: { [key: string]: string } = {
  WORKSPACE_OWNER: 'Owner',
  WORKSPACE_ADMIN: 'Admin',
  WORKSPACE_VIEWER: 'Viewer',
};

export const ENVIRONMENT_USER_ROLE_MAP: { [key: string]: string } = {
  ENVIRONMENT_OWNER: 'Owner',
  ENVIRONMENT_ADMIN: 'Admin',
};

export const INTEGRATION_TYPE_MAP: { [key: string]: string } = {
  SLACK_V1: 'Slack',
};

export const WORKFLOW_STATUS_MAP: { [key: string]: string } = {
  DRAFT: 'Draft',
  ACTIVE: 'Live feature',
};

export function truncateStringWithEllipses(
  str: string,
  truncatedLength: number,
): string {
  return str.length > truncatedLength
    ? str.substring(0, truncatedLength - 1) + String.fromCharCode(8230)
    : str;
}

// function that converts snake_case to sentence case
export function snakeToSentenceCase(str: string): string {
  return str
    .split('_')
    .map((word, index) =>
      index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word,
    )
    .join(' ');
}

// TODO: remove this hack as as part of IA-924
export function convertToExploreLabel(exploreName: string): string {
  const EXPLORE_NAME_TO_LABEL_MAP: Record<string, string> = {
    mart_order_partner_reporting: 'Peek Bookings',
    mart_transaction_partner_reporting: 'Peek Transactions',
    pp_waiver_templates: 'Peek Waivers',
    pp_consumer_reviews: 'Customer Reviews',
    replenishment_planning: 'Replenishment Planning',
    purchase_order_lines: 'Purchase Order Lines',
    purchase_inventory_status: 'Purchase Inventory Status',
    reporting_inventory_status: 'Reporting Inventory Status',
  };
  if (exploreName in EXPLORE_NAME_TO_LABEL_MAP) {
    return EXPLORE_NAME_TO_LABEL_MAP[exploreName];
  }
  return snakeToSentenceCase(exploreName);
}

export const getGreetingMessage = () => {
  const date = new Date();
  const hours = date.getHours();
  if (hours >= 0 && hours < 12) {
    return 'Good morning';
  } else if (hours >= 12 && hours < 18) {
    return 'Good afternoon';
  } else if (hours >= 18 && hours < 24) {
    return 'Good evening';
  }
};

export const sanitizeString = (string: string): string => {
  // Remove HTML tags and trim whitespace
  return string.replace(/<[^>]*>/g, '').trim();
};

import Image from 'next/image';
import Markdown from 'markdown-to-jsx';

import { Stack, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

import { useSessionInfo } from '../../../hooks/session';
import useDynamicField from '../../DynamicFieldInputs/useDynamicField';
import { useContext } from 'react';
import { EnvironmentContext } from '../../EnvironmentProvider';
import { INVENTIVE_ICON } from '../../../utils/filePaths';
export interface SlackPreviewProps {
  message: string;
  vizTitle: string;
}

const SlackPreview = ({ message, vizTitle }: SlackPreviewProps) => {
  const { customBranding } = useSessionInfo();
  const { environment } = useContext(EnvironmentContext);
  const { convertStringToPreviewString } = useDynamicField();

  const SlackTypography = styled(Typography)({
    fontFamily: 'Lato',
    letterSpacing: '0px',
    lineHeight: 1,
  });

  const markdownNewLine = '  \n'; // 2 spaces and a new line
  const fakeReviewLink = '[Review](#)';

  return (
    <Stack spacing={2}>
      <Typography variant='h6'>Message</Typography>
      <Stack
        data-testid='slack-message-preview'
        direction='row'
        spacing={2}
        sx={{
          border: (theme) => `1px solid ${theme.palette.border.light}`,
          borderRadius: 4,
          padding: 2,
        }}
      >
        <Stack data-testid='slack-message-icon'>
          <Image
            src={customBranding?.iconUrl || INVENTIVE_ICON}
            alt='icon'
            width={32}
            height={32}
          />
        </Stack>
        <Stack data-testid='slack-message-content' spacing={1}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <SlackTypography fontWeight={800} fontSize='13px'>
              {environment?.name}
            </SlackTypography>
            <Box
              sx={{
                bgcolor: '#DFDFDF',
                color: '#2E2E2E',
                borderRadius: '3px',
                padding: '2px 4px',
              }}
            >
              <SlackTypography fontSize='9px'>APP</SlackTypography>
            </Box>
            <SlackTypography fontSize='11px'>12:45</SlackTypography>
          </Stack>
          {vizTitle && (
            <SlackTypography fontWeight={400}>{vizTitle}</SlackTypography>
          )}

          <Markdown
            options={{
              overrides: {
                p: {
                  component: Typography,
                  props: {
                    sx: {
                      fontSize: '15px',
                      fontWeight: 400,
                      lineHeight: 1.5,
                      a: {
                        color: '#2392A2',
                        textDecoration: 'none',
                      },
                    },
                  },
                },
              },
            }}
          >
            {convertStringToPreviewString(message) +
              markdownNewLine +
              fakeReviewLink}
          </Markdown>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SlackPreview;

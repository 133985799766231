// This is used to render the header of both Dialog and Drawer
// components/shared/Panel/PanelHeader.tsx
import { Stack, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PANEL_HEADER_HEIGHT } from '../../Layout/constants';
import PanelCloseButton from './PanelCloseButton';
import TruncatedTypography from '../TruncatedTypography';
import { ReactNode } from 'react';

interface PanelHeaderProps {
  title: string;
  titleAction?: ReactNode;
  onClose?: () => void;
  useMobileLayout?: boolean; // show caret icon on the left on mobile. It is true only for drawers
  hideHeader?: boolean; // show absolutely positioned close button only when hideHeader is true
}

const PanelHeader = ({
  title,
  titleAction,
  onClose,
  useMobileLayout = false,
  hideHeader = false,
}: PanelHeaderProps) => {
  const theme = useTheme();
  const isMobile =
    useMediaQuery(theme.breakpoints.down('sm')) && !!useMobileLayout;

  if (hideHeader && onClose) {
    return (
      <PanelCloseButton
        isMobile={isMobile}
        onClose={onClose}
        hideHeader={true}
      />
    );
  }

  return (
    <Stack
      id='panel-header'
      height={PANEL_HEADER_HEIGHT}
      alignItems='center'
      direction={isMobile ? 'row-reverse' : 'row'}
      justifyContent={isMobile ? 'flex-end' : 'space-between'}
      // horizontal padding next to the icon button has to be smaller on mobile
      // icon is on the left on mobile, right on desktop
      paddingLeft={isMobile ? 0.5 : 2}
      paddingRight={isMobile ? 2 : 0.5}
      paddingY={0.5}
      spacing={1}
    >
      {titleAction}
      <TruncatedTypography variant='h5' color='text.primary' sx={{ flex: 1 }}>
        {title}
      </TruncatedTypography>
      {onClose && <PanelCloseButton isMobile={isMobile} onClose={onClose} />}
    </Stack>
  );
};

export default PanelHeader;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Workspace,
  Feature,
  WorkspaceMember,
  Integration,
  WorkspaceLookerDataModelExplore,
  VizGenerationMode,
} from '../../generated/types';

export const GPT4_TURBO = 'gpt-4-turbo-2024-04-09';
export const GPT4_OMNI_2024_08_06 = 'gpt-4o-2024-08-06';
export const GPT4_OMNI_2024_11_20 = 'gpt-4o-2024-11-20';
export const O1_2024_12_17_MODEL = 'o1-2024-12-17';
export const O1_MINI_2024_09_12_MODEL = 'o1-mini-2024-09-12';
export const O3_MINI_2025_01_31 = 'o3-mini-2025-01-31';
export const DEFAULT_MODEL = GPT4_OMNI_2024_08_06;

export const CLAUDE_3_5_SONNET_LATEST = 'anthropic/claude-3-5-sonnet-latest';
export const CLAUDE_3_5_HAIKU_LATEST = 'anthropic/claude-3-5-haiku-latest';
export const CLAUDE_3_OPUS_LATEST = 'anthropic/claude-3-opus-latest';
export const GEMINI_1_5_FLASH = 'gemini/gemini-1.5-flash';
export const GEMINI_1_5_PRO = 'gemini/gemini-1.5-pro';
export const GEMINI_2_0_FLASH_EXP = 'gemini/gemini-2.0-flash-exp';
export const DEEPSEEK_CHAT = 'deepseek/deepseek-chat';
export const DEEPSEEK_REASONER = 'deepseek/deepseek-reasoner';
export const DEEPSEEK_CODER = 'deepseek/deepseek-coder';
export const AZURE_GPT_4O = 'azure/gpt-4o';
export const GROQ_LLAMA_3_3_70B_VERSATILE = 'groq/llama-3.3-70b-versatile';
export const GROQ_LLAMA_3_2_90B_VISION_PREVIEW =
  'groq/llama-3.2-90b-vision-preview';

export const INITIALIZED_WORKSPACE_ID_KEY = 'initializedWorkspaceId';

export enum WorkspaceInitializationState {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  INITIALIZING = 'INITIALIZING',
  INITIALIZED = 'INITIALIZED',
}

export interface ChatConfig {
  temperature: number;
  model: string;
  enableQuickFollow: boolean;
  enableCommonQuestionHints: boolean;
  enableEmbeddings: boolean;
  vizGenerationMode: VizGenerationMode;
  enableQuestionEmbeddings: boolean;
}

interface WorkspaceState {
  workspace?: Workspace;
  features: Feature[];
  members: WorkspaceMember[];
  integrations: Integration[];
  workspaceExplores: WorkspaceLookerDataModelExplore[] | null;

  chatConfig: ChatConfig;
  dataSourceOptions?: {
    value: string;
    label: string;
    filterValues: string[];
  }[];
  initializationState: WorkspaceInitializationState;
}

const initialState: WorkspaceState = {
  workspace: undefined,
  features: [],
  members: [],
  integrations: [],
  workspaceExplores: null,

  chatConfig: {
    temperature: 0.0,
    model: GPT4_OMNI_2024_08_06,

    enableQuickFollow: false,
    enableCommonQuestionHints: true,
    enableEmbeddings: true,
    vizGenerationMode: VizGenerationMode.PLANNER,
    enableQuestionEmbeddings: true,
  },
  dataSourceOptions: undefined,
  initializationState: WorkspaceInitializationState.NOT_INITIALIZED,
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: {
    value: initialState,
  },
  reducers: {
    setWorkspace: (state, action: PayloadAction<Workspace | undefined>) => {
      state.value.workspace = action.payload;
    },
    setFeatures: (state, action: PayloadAction<Feature[]>) => {
      state.value.features = action.payload;
    },
    deleteFeature: (state, action: PayloadAction<string>) => {
      state.value.features = state.value.features.filter(
        (feature) => feature.id !== action.payload,
      );
    },
    updateFeature: (state, action: PayloadAction<Feature>) => {
      state.value.features = state.value.features.map((feature) =>
        feature.id === action.payload.id ? action.payload : feature,
      );
    },
    updateFeatureName: (
      state,
      action: PayloadAction<{ id: string; name: string }>,
    ) => {
      state.value.features = state.value.features.map((feature) =>
        feature.id === action.payload.id
          ? { ...feature, name: action.payload.name }
          : feature,
      );
    },
    addFeature: (state, action: PayloadAction<Feature>) => {
      state.value.features = [...state.value.features, action.payload].sort(
        (a, b) => a.name.localeCompare(b.name),
      );
    },
    setMembers: (state, action: PayloadAction<WorkspaceMember[]>) => {
      state.value.members = action.payload;
    },
    updateMember: (state, action: PayloadAction<WorkspaceMember>) => {
      state.value.members = state.value.members.map((member) =>
        member.id === action.payload.id ? action.payload : member,
      );
    },
    addMember: (state, action: PayloadAction<WorkspaceMember>) => {
      state.value.members = [...state.value.members, action.payload];
    },
    deleteMember: (state, action: PayloadAction<string>) => {
      state.value.members = state.value.members.filter(
        (member) => member.id !== action.payload,
      );
    },
    deactivateMember: (state, action: PayloadAction<string>) => {
      state.value.members = state.value.members.map((member) =>
        member.id === action.payload
          ? { ...member, status: 'DEACTIVATED' }
          : member,
      );
    },
    setIntegrations: (state, action: PayloadAction<Integration[]>) => {
      state.value.integrations = action.payload;
    },
    deleteIntegration: (state, action: PayloadAction<string>) => {
      state.value.integrations = state.value.integrations.filter(
        (integration) => integration.id !== action.payload,
      );
    },
    setWorkspaceExplores: (
      state,
      action: PayloadAction<WorkspaceLookerDataModelExplore[]>,
    ) => {
      state.value.workspaceExplores = action.payload;
    },
    addWorkspaceExplore: (
      state,
      action: PayloadAction<WorkspaceLookerDataModelExplore>,
    ) => {
      state.value = {
        ...state.value,
        workspaceExplores: [
          ...(state.value.workspaceExplores ?? []),
          action.payload,
        ],
      };
    },
    deleteWorkspaceExplore: (state, action: PayloadAction<string>) => {
      state.value = {
        ...state.value,
        workspaceExplores:
          state.value.workspaceExplores?.filter(
            (explore) => explore.id !== action.payload,
          ) ?? null,
      };
    },
    clearWorkspace: () => ({
      value: initialState,
    }),
    updateChatConfig: (state, action: PayloadAction<ChatConfig>) => {
      state.value = {
        ...state.value,
        chatConfig: action.payload,
      };
    },
    setDataSourceOptions: (state, action) => {
      state.value.dataSourceOptions = action.payload;
    },
    setWorkspaceInitializationState: (
      state,
      action: PayloadAction<WorkspaceInitializationState>,
    ) => {
      state.value.initializationState = action.payload;
    },
  },
});

export const {
  setWorkspace,
  setFeatures,
  deleteFeature,
  updateFeature,
  updateFeatureName,
  addFeature,
  setMembers,
  updateMember,
  addMember,
  deleteMember,
  deactivateMember,
  setIntegrations,
  deleteIntegration,
  setWorkspaceExplores,
  addWorkspaceExplore,
  deleteWorkspaceExplore,
  clearWorkspace,
  updateChatConfig,
  setDataSourceOptions,
  setWorkspaceInitializationState,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;

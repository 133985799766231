import { Box, Typography, Icon } from '@mui/material';
import Tooltip from '../Tooltip';
import { alpha } from '@mui/material/styles';
import Image from 'next/image';
import { sanitizeString } from '../../../utils/Strings';

export type AvatarSize = 'large' | 'small';

export const AVATAR_DIMENSIONS = {
  large: {
    size: 32,
    fontSize: 13,
    iconSize: 24,
  },
  small: {
    size: 24,
    fontSize: 11,
    iconSize: 20,
  },
};

const generateInitials = (name: string) => {
  const words = name.trim().split(/\s+/);
  if (words.length === 0) {
    return '';
  } else if (words.length === 1) {
    return words[0][0].toUpperCase();
  } else {
    return (words[0][0] + words[words.length - 1][0]).toUpperCase();
  }
};

export interface AvatarProps {
  size?: 'small' | 'large'; // default is large
  sourceType: 'text' | 'icon' | 'image';
  source: string; // User name for text, material icon name for icon, image url for image
  ariaLabel?: string;
  deactivated?: boolean;
  active?: boolean;
  showTooltip?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Avatar = ({
  size = 'large',
  sourceType = 'text',
  source,
  ariaLabel,
  deactivated,
  active,
  showTooltip = true,
  onClick,
  ...rest
}: AvatarProps) => {
  const initials = sourceType === 'text' ? generateInitials(source) : '';

  const activeBgColor =
    sourceType === 'image' ? 'background.paper' : 'primary.light';

  const tooltipTitle = sourceType === 'text' ? source : ariaLabel;

  const sanitizedAriaLabel = ariaLabel ? sanitizeString(ariaLabel) : undefined;

  return (
    <Tooltip title={showTooltip ? tooltipTitle : undefined}>
      <Box
        data-testid='avatar'
        role={onClick ? 'button' : undefined}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
        onKeyDown={(e) => {
          if (onClick && e.key === 'Enter') {
            onClick(e as unknown as React.MouseEvent<HTMLElement>);
          }
        }}
        sx={{
          position: 'relative',
          width: AVATAR_DIMENSIONS[size].size,
          height: AVATAR_DIMENSIONS[size].size,
          borderRadius: '50%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: deactivated ? 'background.default' : activeBgColor,
          flexShrink: 0, // prevents shrinking in flex container
          cursor: onClick ? 'pointer' : 'default',
          // shadowy border
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '50%',
            border: (theme) =>
              `1px solid  ${
                active
                  ? theme.palette.border.active
                  : alpha(theme.palette.neutrals.shadow, 0.08)
              }`, // Adjust color and opacity
            pointerEvents: 'none', // Ensures it doesn’t interfere with content clicks
          },
        }}
        {...rest}
      >
        {sourceType === 'text' && (
          <Typography
            fontSize={AVATAR_DIMENSIONS[size].fontSize}
            aria-label={sanitizedAriaLabel}
          >
            {initials}
          </Typography>
        )}
        {sourceType === 'icon' && (
          <Icon
            sx={{ fontSize: AVATAR_DIMENSIONS[size].iconSize }}
            aria-label={sanitizedAriaLabel}
          >
            {source}
          </Icon>
        )}
        {sourceType === 'image' && (
          <Image
            src={source}
            alt='avatar'
            width={AVATAR_DIMENSIONS[size].size}
            height={AVATAR_DIMENSIONS[size].size}
            aria-label={sanitizedAriaLabel}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default Avatar;
